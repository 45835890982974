import React, { useRef } from "react";
import { Edit } from "tabler-icons-react";
import { Loader } from "@mantine/core";
import defaultAvatar from "../../Assets/SVGs/header/user.png";
import { ProfileImage, EditIconWrapper } from "../../Pages/MyProfile/Profile/MyView";

const ProfileImageUploader = ({ user, isEditable, onFileChange, isLoadingEditUser }) => {
  const fileInputRef = useRef(null);

  return (
    <div style={{ position: "relative" }}>
      {isLoadingEditUser ? (
        <div style={{ 
          position: "absolute", 
          top: 0, 
          left: 0, 
          right: 0, 
          bottom: 0, 
          display: "flex", 
          alignItems: "center", 
          justifyContent: "center",
          backgroundColor: "rgba(255, 255, 255, 0.7)",
          borderRadius: "50%"
        }}>
          <Loader size="sm" color="teal" />
        </div>
      ) : null}
      <ProfileImage
        src={user?.profile_img?.includes("user-profile") ? user?.profile_img : defaultAvatar}
        alt="profile-img"
      />
      <input 
        type="file" 
        style={{ display: "none" }} 
        ref={fileInputRef} 
        onChange={onFileChange} 
        accept="image/*"
      />
      {isEditable && (
        <EditIconWrapper onClick={() => fileInputRef.current.click()}>
          <Edit size={17} color="#FFB800" />
        </EditIconWrapper>
      )}
    </div>
  );
};

export default ProfileImageUploader;
