import React, { useState, useRef, useEffect } from "react";
import { Group, Stack, Text } from "@mantine/core";
import { Check, Edit, X } from "tabler-icons-react";
import { useMediaQuery } from "@mantine/hooks";
import ProfileField from "../../../Components/Profile/ProfileField";
import ProfileImageUploader from "../../../Components/Profile/ProfileImageUploader";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "../../../Helpers/Axios";

import styled, { keyframes, css } from "styled-components";
import { MultiSelect } from "@mantine/core";

const pulse = keyframes`
  0% {
    border-color: #FFE5E5;
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.4);
  }
  
  70% {
    border-color: #FF5252;
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }
  
  100% {
    border-color: #FFE5E5;
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
`;

export const StyledProfile = styled.div`
  max-width: ${(props) => (props.guid ? "90%" : "1220px")};
  @media (max-width: 1280px) {
    max-width: ${(props) => (props.guid ? "90%" : "1080px")};
  }
  @media (max-width: 1120px) {
    max-width: ${(props) => (props.guid ? "90%" : "970px")};
  }
  @media (max-width: 990px) {
    max-width: 90%;
  }
  @media (max-width: 720px) {
    max-width: 98%;
  }
  margin: 0 auto;
`;

export const ProfileWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  label {
    margin-bottom: 5px;
  }
`;

export const ProfileContainer = styled.div`
  width: ${(props) => (props.guid ? "100%" : "74%")};
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin: 0 0 40px 0;

  //   accordion custom styles
  .mantine-1h6pkea.mantine-Accordion-chevron {
    display: none !important;
  }
  .mantine-Accordion-item.mantine-v4lv9f {
    border-bottom: 0px !important;
  }
  .mantine-UnstyledButton-root.mantine-Accordion-control.mantine-21wjnm {
    border-bottom: 1px solid #e1e1e1 !important;
    padding-left: 0px !important;
    margin-bottom: 8px;
  }
  .mantine-UnstyledButton-root.mantine-Accordion-control.mantine-21wjnm[aria-expanded="false"] {
    border-bottom: 0px !important;
    padding: 10px 0px !important;
    margin-bottom: 0px;

    .mantine-Text-root.mantine-o3i2x9 {
      font-weight: 500 !important;
      font-size: 16px !important;
      color: #979797 !important;
    }
  }
  .mantine-ukbbnm.mantine-Accordion-content {
    padding-left: 0px !important;
  }
  @media (max-width: 1280px) {
    width: ${(props) => (props.guid ? "100%" : "72%")};
  }
  @media (max-width: 1120px) {
    width: ${(props) => (props.guid ? "100%" : "70%")};
  }
  @media (max-width: 990px) {
    width: 100%;
  }
`;

export const AsideContainer = styled.div`
  width: 24%;
  height: 160vh;
  position: sticky;
  top: 100px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  @media (max-width: 1280px) {
    width: 26%;
  }
  @media (max-width: 1120px) {
    width: 28%;
  }

  @media (max-width: 990px) {
    width: 100%;
    position: relative;

    top: 0;
    height: 100%;
  }
`;

export const ViewContainer = styled.div`
  box-shadow: 0px 1px 10px 0px #0000001a;
  width: 100%;
  border-radius: 11px;
  background-color: #fff;
  border: 1px solid
    ${(props) => (props.isRequired && props.isEmpty ? "#FF5252" : "#fff")};
  background-color: ${(props) =>
    props.isRequired && props.isEmpty ? "#FFF5F5" : "#fff"};
  ${(props) =>
    props.isRequired &&
    props.isEmpty &&
    css`
      animation: ${pulse} 2s infinite;
    `}
  transition: all 0.3s ease;
`;

export const ProfileImage = styled.img`
  object-fit: cover;
  width: ${(props) => (props.isMobile ? "70px" : "88px")};
  height: ${(props) => (props.isMobile ? "70px" : "88px")};
  border-radius: 50%;
`;

export const EditIconWrapper = styled.div`
  background: #fff;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  bottom: 2px;
  right: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 15px 0px #0000001a;
`;

export const ActionButton = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background-color: ${(props) =>
    props.variant === "confirm" ? "#D6F4F6" : "#fff3f3"};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EditText = styled.span`
  font-size: 14px;
`;

export const DataContainer = styled.div`
  ${(props) => {
    // Əgər first_name, last_name və ya fields-dirsə, heç bir stil tətbiq etmə
    if (["first_name", "last_name", "fields_ids"].includes(props.name)) {
      return "";
    }

    // Əks halda bütün stilləri tətbiq et
    return css`
      border: 1px solid
        ${props.$isRequired && props.$isEmpty ? "#FF5252" : "#e7e9ed"};
      background-color: ${props.$isRequired && props.$isEmpty
        ? "#FFF5F5"
        : "#f9fafc"};
      padding: 10px 20px;
      border-radius: 17px;
      ${props.$isRequired &&
      props.$isEmpty &&
      css`
        animation: ${pulse} 2s infinite;
      `}
      transition: all 0.3s ease;

      @media (max-width: 768px) {
        padding: 8px 15px;
        border-radius: 12px;
      }
    `;
  }}
`;

export const BlurredText = styled(Text)`
  filter: ${(props) => (!props.$isBlurred ? "blur(4px)" : "none")};
  transition: filter 0.3s ease;

  &:hover {
    filter: none;
  }
`;

export const StyledMultiSelect = styled(MultiSelect)`
  margin-left: 0px;
  @media (max-width: 360px) {
    width: 85%;
  }
  ${(props) =>
    props.isEmpty &&
    css`
      .mantine-MultiSelect-input {
        border-color: #ff5252 !important;
        background-color: #fff5f5 !important;
        animation: ${pulse} 2s infinite !important;
      }
    `}
`;

export const MyView = ({
  t,
  user,
  publicUser,
  isChangedLocal,
  setIsChangedLocal,
  isAdmin,
  isAgent,
  isCompany,
}) => {
  const [isEditable, setIsEditable] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const queryClient = useQueryClient();

  const [userData, setUserData] = useState({
    first_name: publicUser?.first_name || user?.first_name || "",
    last_name: publicUser?.last_name || user?.last_name || "",
    fields_ids: publicUser?.fields || user?.fields || [],
    number: publicUser?.number || user?.number || "",
    birthday: publicUser?.birthday || user?.birthday || "",
    address: publicUser?.address || user?.address || "",
    website: publicUser?.website || user?.website || "",
    email: publicUser?.email || user?.email || "",
  });

  useEffect(() => {
    setUserData({
      first_name: publicUser?.first_name || user?.first_name || "",
      last_name: publicUser?.last_name || user?.last_name || "",
      fields_ids: publicUser?.fields || user?.fields || [],
      number: publicUser?.number || user?.number || "",
      birthday: publicUser?.birthday || user?.birthday || "",
      address: publicUser?.address || user?.address || "",
      website: publicUser?.website || user?.website || "",
      email: publicUser?.email || user?.email || "",
    });
  }, [user, publicUser]);

  const { mutate: editUser, isLoading: isLoadingEditUser } = useMutation({
    mutationFn: async (data) => {
      const response = await axios.patch(
        `/api/v1/edit-user/${user?.id}/`,
        data
      );
      return response.data;
    },
    onSuccess: (data) => {
      localStorage.setItem("taskooluser", JSON.stringify(data));
      setIsEditable(false);
      setIsChangedLocal(!isChangedLocal);
    },
    onError: (error) => {
      console.log("error", error);
    },
  });

  const { data: showEmail } = useQuery({
    queryKey: ["showEmail", publicUser ? publicUser?.id : user?.id],
    queryFn: async () => {
      const response = await axios.get(
        `/api/v1/profile-permission/${publicUser ? publicUser?.id : user?.id}/`
      );
      return response.data;
    },
  });

  const { mutate: editShowEmail } = useMutation({
    mutationFn: async (data) => {
      const response = await axios.patch(
        `/api/v1/profile-permission/${user?.id}/`,
        data
      );
      return response.data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(["showEmail", user.id]);
    },
  });

  const handleShowEmail = (isBoolean) => {
    const formData = new FormData();
    formData.append("email", isBoolean ? "True" : "False");
    editShowEmail(formData);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {};
      reader.readAsDataURL(file);
    }
  };

  const { data: fields } = useQuery({
    queryKey: ["fields"],
    queryFn: async () => {
      const res = await axios.get("/api/v1/speciality/");
      return res.data;
    },
  });

  const handleEditIconClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("profile_img", selectedFile);
      editUser(formData);
    }
  }, [selectedFile]);

  const xs = useMediaQuery("(max-width: 768px)");
  const mobile = useMediaQuery("(max-width: 480px)");
  const existUserFields = publicUser ? publicUser?.fields : user?.fields;

  return (
    <ViewContainer>
      <Stack style={{ padding: "20px" }}>
        <Group
          style={{ width: "100%", position: "relative" }}
          noWrap
          align="flex-start"
          position="apart"
        >
          <Group noWrap={!xs} align="center" spacing={20}>
            <ProfileImageUploader
              user={publicUser ? publicUser : user}
              isEditable={isEditable}
              onFileChange={handleFileChange}
              isLoadingEditUser={isLoadingEditUser}
            />
            <Stack spacing={4}>
              <Group spacing={4}>
                <ProfileField
                  label={t("name")}
                  name="first_name"
                  value={userData.first_name}
                  isEditable={isEditable}
                  onChange={handleChange}
                  isShow={true}
                />
                <ProfileField
                  label={t("surname")}
                  name="last_name"
                  value={userData.last_name}
                  isEditable={isEditable}
                  onChange={handleChange}
                  isShow={!isCompany && !isAgent}
                />
              </Group>
              <ProfileField
                label={t("fields")}
                name="fields_ids"
                value={userData.fields_ids}
                isEditable={isEditable}
                isMultiSelect={true}
                multiSelectData={fields}
                onChange={handleChange}
                isShow={!isCompany && !isAgent}
              />
            </Stack>
          </Group>
          {isEditable ? (
            <Group
              style={{
                position: mobile ? "absolute" : "relative",
                right: 0,
                top: 0,
              }}
              noWrap
              position="right"
              spacing={10}
            >
              <ActionButton
                variant="confirm"
                onClick={() =>
                  editUser({
                    ...userData,
                    fields_ids: userData.fields_ids.map((item) =>
                      item?.id ? item.id : item
                    ),
                  })
                }
              >
                <Check color="#029199" strokeWidth={1.75} size={16} />
              </ActionButton>
              <ActionButton onClick={() => setIsEditable(false)}>
                <X color="#E41D1D" strokeWidth={1.75} size={16} />
              </ActionButton>
            </Group>
          ) : (
            !publicUser && (
              <Group
                onClick={() => setIsEditable(true)}
                spacing={8}
                align="center"
                position="right"
                noWrap
                style={{ cursor: "pointer" }}
              >
                <Edit
                  color="#364152"
                  strokeWidth={1.75}
                  style={{ cursor: "pointer" }}
                />
                {!xs && <Text>{t("edit")}</Text>}
              </Group>
            )
          )}
        </Group>
        <Group>
          {((!isEditable && !publicUser) || (!showEmail?.email && publicUser)) && (
            <ProfileField
              label={t("e-mail")}
              name="email"
              value={userData.email}
              isEditable={isEditable}
              isEmail={true}
              showEmail={showEmail?.email}
              onEmailToggle={ isAgent || isCompany ? null : handleShowEmail}
              isShow={true}

            />
          )}
          {!(isCompany || isAgent) && (
            <ProfileField
              label={t("birth-of-date")}
              name="birthday"
              value={userData.birthday}
              isEditable={isEditable}
              isDate={true}
              onChange={handleChange}
              isShow={true}
            />
          )}
          <ProfileField
            label={t("location")}
            name="address"
            value={userData.address}
            isEditable={isEditable}
            onChange={handleChange}
            isShow={true}
          />
          <ProfileField
            label={t("mobile")}
            name="number"
            value={userData.number}
            isEditable={isEditable}
            onChange={handleChange}
            isShow={true}
          />
          {(isCompany || isAgent) && (
            <ProfileField
              label={t("website")}
              name="website"
              value={userData.website}
              isEditable={isEditable}
              onChange={handleChange}
              isShow={true}
            />
          )}
        </Group>
      </Stack>
    </ViewContainer>
  );
};

export default MyView;
