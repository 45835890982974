import { Button } from "@mantine/core";
import axiosInstance from "../../Helpers/Axios";
import { Link, useNavigate } from "react-router-dom";
import { FreeCourseStyle } from "./PaymentStyle";
import { useQueryClient } from "@tanstack/react-query";
import { showNotification } from "@mantine/notifications";

export function FreeCourse({ props }) {
  const {t, type, texts, links, userLoggedIn, user, entryData ,promoEntered,id,slug} = props;
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  async function buyFreeCourse() {
    try {
      const formX = new FormData();
      if (type === "internship") {
        formX.append("intershipinfo", entryData?.id);
      }
      if (type === "course") {
        formX.append("course", entryData?.id);
      }

      if (type === "steam" ) {
        formX.append("steam", id);
      }
      if (type === "bootcamp") {
        formX.append("bootcamp", id);
      }
      if (type === "community") {
        formX.append("community",id);
      }
      if (type === "competition") {
        formX.append("competition",id);
      }
      formX.append("promocode", promoEntered);
      formX.append("bank_installment_paid",false)

      const response = await axiosInstance.post(links.create_order, formX);

      if (response.status === 201) {
        if (type === "internship") {
          navigate(`/internship-content/${id}`);
        }
        if (type === "course") {
          navigate(`/courses/${id}`);
        }

        if (type === "steam") {
          navigate(`/steam/${id}`);
        }
        if (type === "bootcamp") {
          navigate(`/bootcamp/${id}`);
        }
        if (type === "community") {
          navigate(`/community/${id}`);
        }
        if (type === "competition") {
          queryClient.invalidateQueries({ queryKey: ["competition", entryData?.id] });
          navigate(`/competitions/${slug}-${entryData?.id}?status=Accepted`);
        }
      }
    } catch (error) {
       if(error.response.status === 400 && type === "competition" && error.response.data === "Non-subscribers can only participate in free Olympiads once every six months."){
        showNotification({
          title: t("error"),
          message: t("competition_error_message"),
          color: "red",
        });
        // Scroll to subscription section
        const subscriptionElement = document.querySelector('.subscription');
        if (subscriptionElement) {
          subscriptionElement.scrollIntoView({ behavior: 'smooth' });
        }
       }
    }
  }

  return (
    <FreeCourseStyle>
      {/* <div className="price">
        <ins>Price:</ins>
        <h2 style={{marginTop:"0rem"}}>FREE</h2>
      </div> */}

      {userLoggedIn ? (
        <Button
          fullWidth
          className="payment-btn"
          onClick={() => buyFreeCourse()}
        >
          {texts.apply}: {t('FREE')}
        </Button>
      ) : (
        <Button
          fullWidth
          className="payment-btn"
          component={Link}
          to="/login"
        >
          {texts.log_in}
        </Button>
      )}
    </FreeCourseStyle>
  );
}