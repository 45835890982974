import React from "react";
import {
  DataContainer,
  BlurredText,
  StyledMultiSelect,
} from "../../Pages/MyProfile/Profile/MyView";
import { Text, TextInput, Group } from "@mantine/core";
import { Eye, EyeOff } from "tabler-icons-react";
import { DatePicker } from "@mantine/dates";

const ProfileField = ({
  label,
  name,
  value,
  isEditable,
  isEmail,
  isDate,
  isMultiSelect,
  multiSelectData,
  showEmail,
  onEmailToggle,
  onChange,
  isShow,
  isAgent,
  isCompany,
}) => {
  const isUpData =
    name.includes("fields_ids") ||
    name.includes("first_name") ||
    name.includes("last_name");
  const isFullName = name.includes("first_name") || name.includes("last_name");
  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }
  return (
    <DataContainer
      name={name}
      $isRequired={true}
      $isEmpty={!value || (isMultiSelect && value.length === 0)}
    >
      {isUpData ? null : (
        <Text size={13} weight={500} color="#979797">
          {label}
        </Text>
      )}
      {isEditable ? (
        isDate ? (
          <DatePicker
            size="md"
            radius={10}
            placeholder="Pick a date"
            name={name}
            value={value ? new Date(value) : null}
            onChange={(e) =>
              onChange({ target: { name, value: formatDate(e) } })
            }
          />
        ) : isMultiSelect && isShow ? (
          <StyledMultiSelect
            mt={10}
            ml={5}
            size="md"
            radius={10}
            data={
              multiSelectData?.map((item) => ({
                value: item.id,
                label: item.field,
              })) || []
            }
            defaultValue={value?.map((item) => item.id)}
            onChange={(selectedValues) => {
              onChange({
                target: {
                  name,
                  value: selectedValues,
                },
              });
            }}
          />
        ) : isShow ? (
          <TextInput
            size="md"
            radius={10}
            name={name}
            value={value}
            onChange={onChange}
            readOnly={!onChange}
          />
        ) : null
      ) : (
        <Group noWrap align="center">
          {isEmail ? (
            <>
              <BlurredText
                $isBlurred={!showEmail}
                size={14}
                weight={400}
                color="#364152"
              >
                {value}
              </BlurredText>
              {onEmailToggle &&
                (showEmail ? (
                  <EyeOff
                    style={{ cursor: "pointer" }}
                    strokeWidth={1.75}
                    color="#686868"
                    size={15}
                    onClick={() => onEmailToggle(false)}
                  />
                ) : (
                  <Eye
                    style={{ cursor: "pointer" }}
                    strokeWidth={1.75}
                    color="#686868"
                    size={15}
                    onClick={() => onEmailToggle(true)}
                  />
                ))}
            </>
          ) : isMultiSelect && isShow ? (
            <Group spacing={2}>
              {value?.length > 0 ? (
                value.map((field, index) => (
                  <Text key={index} size={16} weight={400} color="#979797">
                    {field.field} {index !== value.length - 1 && "/"}
                  </Text>
                ))
              ) : (
                <Text size={16} weight={400} color="#979797">
                  N/A
                </Text>
              )}
            </Group>
          ) : isShow ? (
            <Text
              size={isFullName ? 20 : 14}
              weight={isFullName ? 700 : 400}
              color="#364152"
            >
              {value || "N/A"}
            </Text>
          ) : null}
        </Group>
      )}
    </DataContainer>
  );
};

export default ProfileField;
