import Cookies from "universal-cookie";

const cookies = new Cookies();
const envDomain = process.env.REACT_APP_DOMAIN;

export const cookieUtils = {
  // Cookie'ye değer set etmek için
  setCookie: (name, value, options = {}) => {
    const defaultOptions = {
      path: "/",
      domain: `${envDomain}`,
      // domain: "",
      ...options,
    };
    cookies.set(name, value, defaultOptions);
  },

  // Cookie'den değer almak için
  getCookie: (name) => {
    return cookies.get(name);
  },

  // Cookie'den değer silmek için
  // domain: "localhost"
  removeCookie: (name, options = {}) => {
    const defaultOptions = {
      path: "/",
      domain: `${envDomain}`,
      // domain: "",
      ...options,
    };
    cookies.remove(name, defaultOptions);
  },

  // Tüm cookie'leri temizlemek için
  clearAllCookies: () => {
    const allCookies = cookies.getAll();
    Object.keys(allCookies).forEach((cookieName) => {
      cookies.remove(cookieName, {
        path: "/",
          domain: `${envDomain}`,
        // domain: "",
      });
    });
  },

  // Auth ile ilgili cookie'leri set etmek için
  setAuthCookies: (accessToken, refreshToken) => {
    cookieUtils.setCookie("access", accessToken);
    cookieUtils.setCookie("refresh", refreshToken);
  },

  // Auth cookie'lerini temizlemek için
  clearAuthCookies: () => {
    cookieUtils.removeCookie("access");
    cookieUtils.removeCookie("refresh");
  },
};
